/* Mixins */
.event-view-detail-component .event-view-detail-section .ant-card-head {
  background: #f7f7f7;
}
.event-view-detail-component .event-view-detail-section .ant-card-head .event-view-detail-title {
  font-size: 20px;
  font-weight: bold;
}
.event-view-detail-component .event-view-detail-section .ant-card-body .event-view-detail-section-row {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  margin: 5px -8px !important;
}
.event-view-detail-component .event-view-detail-section .ant-card-body .event-view-detail-section-row .img-row:hover {
  cursor: pointer;
}